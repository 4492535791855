<template>
  <div>
    <div class="row mb-4">
      <div class="col-md-2">
        <label>Select custom range</label>
        <flat-pickr
            v-model="selectedDates"
            class="form-control"
            :config="{ mode: 'range' }"
            name="date"
            :style="{ backgroundColor: '#FFF' }"
            @on-change="handleDateChanged"
          ></flat-pickr>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title mt-0 mb-0 header-title">Number of SMS</h5>
            <div class="text-muted mt-1">Last 30 days</div>
            <div class="media">
              <div class="media-body">
                <p v-if="reports && !loadingReport" class="mt-2">
                  Number of Incoming SMS is up <strong class="text-success"
                    >up {{ reports.sms_week }}%</strong
                  > over last week and <strong class="text-success"
                    >up {{ reports.sms_month }}%</strong
                  > over last month
                </p>
                <div v-else>
                  <b-spinner />
                </div>
              </div>
            </div>
            <apexcharts
              v-if="reports && !loadingReport"
              type="bar"
              height="296"
              :series="smsChart.series"
              :options="smsChart.chartOptions"
            ></apexcharts>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import moment from 'moment-timezone'

export default {
  components: {
    apexcharts: VueApexCharts,
  },

  data() {
    return {
      reports: null,
      smsChartData: [],
      smsChart: {
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          tooltip: {
            theme: 'dark',
            x: { show: false },
          },
          stroke: {
            curve: 'smooth',
            width: 4,
          },
          dataLabels: {
            enabled: false,
          },
          colors: ['#43d39e', '#4284f4'],
          xaxis: {
            type: 'string',
            categories: [],
            tooltip: {
              enabled: false,
            },
            axisBorder: {
              show: false,
            },
          },
        },
        series: [],
      },
      selectedDates: [
        moment()
          .subtract(30, 'days')
          .format(),
        moment().format(),
      ],
    }
  },

  mounted() {
    this.getReport(this.selectedDates)
  },

  methods: {
    getReport(dateRange) {
      this.loadingReport = true
      this.reports = null

      this.$store
        .dispatch('report/adminDashboard', dateRange)
        .then((reports) => {
          this.reports = reports

          this.smsChart.chartOptions.xaxis.categories = reports.labels
          this.smsChart.series = reports.series

          this.loadingReport = false
        })
        .catch(() => {
          this.loadingReport = false
        })
    },

    selectRange(event) {
      switch (event.target.value) {
        case 'last 3 days':
          this.selectedDates = [
            moment().format(),
            moment()
              .subtract(3, 'days')
              .format(),
          ]
          break
        case 'last 7 days':
          this.selectedDates = [
            moment().format(),
            moment()
              .subtract(7, 'days')
              .format(),
          ]
          break
        case 'this week':
          this.selectedDates = [
            moment()
              .startOf('isoWeek')
              .format(),
            moment()
              .endOf('isoWeek')
              .format(),
          ]
          break
        case 'last week':
          this.selectedDates = [
            moment()
              .subtract(1, 'weeks')
              .startOf('isoWeek')
              .format(),
            moment()
              .subtract(1, 'weeks')
              .endOf('isoWeek')
              .format(),
          ]
          break
        case 'this month':
          this.selectedDates = [
            moment()
              .startOf('month')
              .format(),
            moment().format(),
          ]
          break
        case 'last month':
          this.selectedDates = [
            moment()
              .subtract(1, 'months')
              .startOf('month')
              .format(),
            moment()
              .subtract(1, 'months')
              .endOf('month')
              .format(),
          ]
          break
      }
    },

    handleDateChanged(selectedDates) {
      if (selectedDates.length === 2) {
        this.getReport(selectedDates)
      }
    },
  },
}
</script>
